import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Table, Form, Pagination, Button } from "react-bootstrap";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";

const ExtraAccessControl = () => {
  const [ExtraAccessControl, setExtraAccessControl] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [masterSwitches, setMasterSwitches] = useState({
    walletExceed: false,
    nonStandard: false,
    ECO: false,
    MCO: false,
  });
  // Track which switches have been changed
  const [changedSwitches, setChangedSwitches] = useState({});
  const [isUpdatingAll, setIsUpdatingAll] = useState(false);

  const titleVal = [
    { "Wallet Exceed": "walletExceed" },
    { "Non-Standard": "nonStandard" },
    { "Electronic-CO": "ECO" },
    { "Semi-Electronic-CO": "MCO" },
  ];

  const handleMasterSwitchChange = (accessType) => {
    const newValue = !masterSwitches[accessType];
    setMasterSwitches(prev => ({
      ...prev,
      [accessType]: newValue
    }));
    // Track this switch as changed
    setChangedSwitches(prev => ({
      ...prev,
      [accessType]: newValue
    }));
  };

  const handleUpdateAllUsers = async () => {
    // If no switches were changed, don't make the API call
    if (Object.keys(changedSwitches).length === 0) {
      alert("No changes to update");
      return;
    }

    setIsUpdatingAll(true);
    try {
      // Only send the switches that were actually changed
      const accessUpdates = Object.entries(changedSwitches).map(([accessType, isEnabled]) => ({
        accessType,
        isEnabled
      }));

      const res = await axios.post(getURLbyEndPoint("updateAllUsersAccess"), {
        accessUpdates
      });

      if (res.data.status) {
        alert("Updated all users successfully");
        setChangedSwitches({}); // Clear the changed switches after successful update
        fetchNonStandDoc(); // Refresh the data
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error(error);
      alert("Something went wrong when updating all users");
    } finally {
      setIsUpdatingAll(false);
    }
  };

  const handleAccessListChange = async (e, accessType, id) => {
    const isChecked = e.target.checked;
    const updatedEmpList = ExtraAccessControl.map((employee) => {
      if (employee._id === id) {
        const newAccessList = isChecked
          ? [...employee.accessList, accessType]
          : employee.accessList.filter((item) => item !== accessType);
        return { ...employee, accessList: newAccessList };
      }
      return employee;
    });
    setExtraAccessControl(updatedEmpList);
  };

  const handleUpdateSpecialAccessControl = async (userId) => {
    const userData = ExtraAccessControl.find((emp) => emp._id === userId);

    if (!userData) {
      alert("Something went Wrong");
      return;
    }

    try {
      const res = await axios.post(
        getURLbyEndPoint("updateSpecialAccess") + userId,
        {
          accessList: userData.accessList,
        }
      );

      if (res.data.status) {
        alert("updated successfully");
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error(error);
      alert("something went wrong when updating");
    }
  };

  const fetchNonStandDoc = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getSpecialControlsMaster")
      );
      if (response.status === 200) {
        const data = response.data.data;
        setExtraAccessControl(data);

        // Set initial master switch states based on whether all users have the access
        const initialMasterSwitches = {};
        titleVal.forEach((item) => {
          const accessType = Object.values(item)[0];
          const allHaveAccess = data.every((user) =>
            user.accessList.includes(accessType)
          );
          initialMasterSwitches[accessType] = allHaveAccess;
        });
        setMasterSwitches(initialMasterSwitches);
      } else {
        alert("Failed to fetch co data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchNonStandDoc();
  }, []);

  const filteredData = ExtraAccessControl.filter((data) => {
    if (
      searchTerm !== "" &&
      !data.email.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4);

    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader
            heading="Extra Access Control"
            backUrl="/special-controls"
          />
          <hr />

          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search Company Email"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="align-middle">Sr No.</th>
                <th className="align-middle">Username</th>
                {titleVal.map((item) => (
                  <th key={Object.keys(item)[0]} className="text-center align-middle">
                    {Object.keys(item)[0]}
                  </th>
                ))}
                <th className="text-center align-middle">Action</th>
              </tr>
            </thead>
            <tbody>
              {/* Master Controls Row */}
              <tr className="bg-light">
                <td>-</td>
                <td><strong>All Users Control</strong></td>
                {titleVal.map((item) => {
                  const accessType = Object.values(item)[0];
                  return (
                    <td
                      key={accessType}
                      className="text-center align-middle"
                    >
                      <Form.Check
                        type="switch"
                        id={`master-switch-${accessType}`}
                        checked={masterSwitches[accessType]}
                        onChange={() => handleMasterSwitchChange(accessType)}
                        // Visual indicator for changed switches
                        className={changedSwitches.hasOwnProperty(accessType) ? 'switch-changed' : ''}
                      />
                    </td>
                  );
                })}
                <td className="text-center align-middle">
                  <Button
                    className="rounded-4 border-none"
                    type="button"
                    style={{
                      padding: "6px 20px",
                      backgroundColor: "#0b3b5d",
                      color: "white",
                      fontSize: "14px",
                    }}
                    onClick={handleUpdateAllUsers}
                    disabled={isUpdatingAll || Object.keys(changedSwitches).length === 0}
                  >
                    {isUpdatingAll ? "Updating..." : "Update All"}
                  </Button>
                </td>
              </tr>

              {/* Separator Row */}
              <tr>
                <td colSpan={titleVal.length + 3} className="p-0">
                  <hr className="my-0" />
                </td>
              </tr>

              {/* Individual Users Rows */}
              {currentFiltered.length > 0 ? (
                currentFiltered.map((data, index) => (
                  <tr key={index}>
                    <td>{filteredData.length - indexOfFirstItem - index}.</td>
                    <td>{data.email}</td>
                    {titleVal.map((item, idx) => (
                      <td
                        key={idx}
                        className="text-center align-middle"
                      >
                        <Form.Check
                          type="switch"
                          id={`custom-switch-${index}-${idx}`}
                          checked={data.accessList?.includes(
                            Object.values(item)[0]
                          )}
                          onChange={(e) =>
                            handleAccessListChange(
                              e,
                              Object.values(item)[0],
                              data._id
                            )
                          }
                        />
                      </td>
                    ))}
                    <td className="text-center align-middle">
                      <Button
                        className="rounded-4 border-none"
                        type="button"
                        style={{
                          padding: "6px 20px",
                          backgroundColor: "#0b3b5d",
                          color: "white",
                          fontSize: "14px",
                        }}
                        onClick={() => handleUpdateSpecialAccessControl(data._id)}
                      >
                        Update
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={titleVal.length + 3} className="text-center">
                    No Matching Data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default ExtraAccessControl;