export const domain = "https://e-platapi.aecci.org.in";
// export const domain = "http://localhost:3001";

const endpoints = {
  loginMaster: "/loginMaster/",

  //master apis
  registerdUsers: "/registerdUsers/",
  partiallyReviewed: "/partiallyReviewed/",
  fullyReviewed: "/fullyReviewed/",
  changeClientDetails: "/changeClientDetails/",
  createEmp: "/createEmp/", 
  getEmpList: "/getEmpList/",
  getActiveEmpList: "/getActiveEmpList/",
  ChangeEmpStatus: "/ChangeEmpStatus/",
  updateEmpAccess: "/updateEmpAccess/",

  //trial User Apis 
  getAllTrialClient: "/getAllTrialClient/",
  approveRejectTrialUser: "/approveRejectTrialUser/",

  // account disabled api
  disableEnableAccount: "/disableEnableAccount/",

  //publication Api
  createPublication: "/createPublication/",
  getPublication: "/getPublication/",
  deletePublication: "/deletePublication/:id",

  //Ads Api
  getAllAds: "/ads/getAllAds/",
  updateAd: "/ads/updateAd/",
  approveRejectAds: "/ads/approveRejectAds/",
  uploadAd: "/ads/uploadAd/",
  getAllApprovedAdsData: "/ads/getAllApprovedAdsData/",

  //arbitration apis
  getAllRaiseYourDisputesData: "/arbitration/getAllRaiseYourDisputesData",
  updateAdminFee: "/arbitration/updateAdminFee/",
  sendArbitrationPaymentReq: "/arbitration/sendArbitrationPaymentReq/",
  approveRejectNonDispute: "/arbitration/approveRejectNonDispute/",
  approveRejectDocs: "/arbitration/approveRejectDocs/",
  uploadArbitrationFiles: "/arbitration/uploadArbitrationFiles/",

  //event Api
  createEvent: "/createEvent/",
  getAllEvents: "/getAllEvents/",
  getAllRegisterEvent: "/getAllRegisterEvent/",
  approveRejectEvent: "/approveRejectEvent/",
  deleteEvent: "/deleteEvent/",

  //profile apis
  getUpdatePersonalDetails: "/getUpdatePersonalDetails/",
  getUpdateCompanyDetails: "/getUpdateCompanyDetails/",
  getAllRegistrationDocs: "/getAllRegistrationDocs/",
  getUserDataSuperAdmin: "/getUserDataSuperAdmin/",
  rejectApplication: "/rejectApplication/",
  rejectDocuments: "/rejectDocuments/",
  approveUser: "/approveUser/",
  ChangeAccessList: "/ChangeAccessList/",
  updateRejectProfile: "/updateRejectProfile/",
  updateRejectCompany: "/updateRejectCompany/",
  getChangePassword: "/getChangePassword/",
  sendReqRegFile: "/sendReqRegFile/",
  // getChangePassword: "/getChangePassword/",
  getMarketingInfo: "/getMarketingInfo/",
  getCollabrationPortal: "/getCollabrationPortal/",
  getClientVerDetails: "/getClientVerDetails/",
  updateTargetDemoComplete: "/updateTargetDemoComplete/",

  //services membership
  getMembRenewReq: "/getMembRenewReq/",
  updateRejectMembRenew: "/updateRejectMembRenew/",

  getVisaRecommendationDataMaster: "/getVisaRecommendationDataMaster/",
  acceptVisaLetter: "/acceptVisaLetter/",
  rejectVisaLetter: "/rejectVisaLetter/",
  visaDataUpdate: "/visaDataUpdate/",
  getAddFundRequests: "/getAddFundRequests/",
  acceptRejectAddFund: "/acceptRejectAddFund/",
  getCommercialDirData: "/getCommercialDirData/",
  updateRejectComDir: "/updateRejectComDir/",
  updateRejectMarInfo: "/updateRejectMarInfo/",
  addDeductBalSuperAdmin: "/addDeductBalSuperAdmin/",

  getMasterEcoData: "/getMasterEcoData/",
  getMasterMcoData: "/getMasterMcoData/",
  getNonStandDocMaster: "/getNonStandDocMaster/",
  checkStampNonStand: "/checkStampNonStand/",
  addStampNonStand: "/addStampNonStand/",
  changeToA4: "/changeToA4/",
  approveRejectEco: "/approveRejectEco/",
  approveRejectMco: "/approveRejectMco/",
  approveGenerateRef: "/approveGenerateRef/",
  approveRejectNonStand: "/approveRejectNonStand/",

  //download apis
  downloadEcoFile: "/downloadEcoFile/",

  //e-co verification apis
  getOfficerName: "/getOfficerName/",
  getCoResource: "/getCoResource/",
  updateOfficerList: "/updateOfficerList/",
  createNewCo: "/createNewCo/",
  delCoResource: "/delCoResource/",
  uploadCoResource: "/uploadCoResource/",

  upgradeRejectMembership: "/upgradeRejectMembership/",

  //THE WING APIS-----------------------------
  getWingData: "/wing/getWingData/",
  appRejQuery: "/wing/appRejQuery/",
  appRejQueryLegal: "/wing/appRejQueryLegal/",
  appRejQueryBusiness: "/wing/appRejQueryBusiness/",
  appRejQueryProfessional: "/wing/appRejQueryProfessional/",
  appRejQueryEvent: "/wing/appRejQueryEvent/",
  appRejQueryHr: "/wing/appRejQueryHr/",
  updateChat: "/wing/updateChat/",

  //HR ALL APIS-----------------------------
  createJdReport: "/hr/createJdReport/",
  fetchEmpJdReport: "/hr/fetchEmpJdReport/",
  endTaskEmp: "/hr/endTaskEmp/",
  extendTime: "/hr/extendTime/",
  submitJd: "/hr/submitJd/",


  //legal APis
  getAllLegalWingRequest: "/wing/getAllLegalWingRequest/",

  //B2B connect APIS
  createB2BTarget: "/b2b/createB2BTarget/",
  getAllB2bEventRequest: "/b2b/getAllB2bEventRequest/",
  updateTargetOneComplete: "/b2b/updateTargetOneComplete/",
  updateTargetTwoComplete: "/b2b/updateTargetTwoComplete/",
  createB2BTargetTwo: "/b2b/createB2BTargetTwo/",
  approveRejectB2b: "/b2b/approveRejectB2b/",
  updateB2bHistory: "/b2b/updateB2bHistory/",
  approveRejectB2bSponsorship: "/b2b/approveRejectB2bSponsorship/",
  approveRejectB2bIndividual: "/b2b/approveRejectB2bIndividual/",
  handleSendWelcome: "/b2b/handleSendWelcome/",
  sendPaymentReq: "/b2b/sendPaymentReq/",
  approveCollabDoc: '/approveCollabDoc/',
  approveInvitation: '/approveInvitation/',
  approveRejectB2bSlot: '/b2b/approveRejectB2bSlot/',
  getB2bCountrySlots: '/b2b/getB2bCountrySlots/',
  updateB2bCountrySlots: '/b2b/updateB2bCountrySlots/',
  getAllB2bRequest: '/b2b/getAllB2bRequest/',
  sendB2bMail: '/b2b/sendB2bMail',

  //special control apis---------------------
  getSpecialControlsMaster: "/getSpecialControlsMaster/",
  updateSpecialAccess: "/updateSpecialAccess/",
  updateAllUsersAccess: "/updateAllUsersAccess/",
  getPriceControlsMaster: "/getPriceControlsMaster/",
  updatePriceControl: "/updatePriceControl/",
  getDumpFiles: "/getDumpFiles/",

  //REPORTS AND ANALYSIS APISS---------------------
  exportDSR: "/report/export-DSR/",
  walletData: "/report/wallet-Data/",
  getAllAnalysisData: "/report/getAllAnalysisData/",
  getReportData: "/report/getReportData/",
  getCompList: "/report/get-company-list/",
  //notification api
  getNotificationStatus: "/getNotificationStatus/",
};

function getURLbyEndPoint(endpoint, id) {
  let url = domain + endpoints[endpoint];
  if (id) {
    // Replace the placeholder with the actual ID
    url = url.replace(":id", id);
  }
  return url;
}

function getURLbyEndPointV2(endpoint, id) {
  let url = domain + "/api/v2" + endpoints[endpoint];
  if (id) {
    // Replace the placeholder with the actual ID
    url = url.replace(":id", id);
  }
  return url;
}

export { endpoints, getURLbyEndPoint, getURLbyEndPointV2 };

